var _ = require('lodash');

///
/// Transform an ordinary array to one that can be used for altering and paging functions
///
export function getLodashArray(srcArray) {
  return _(srcArray);
}

///
/// alters each element in an array according to the function mapFn
///
export function alterArrayItems(srcLodashArray, mapFn) {
  return srcLodashArray.map((i) => mapFn(i));
}

///
/// Gets a filtered and sorted page of data
///
/// srcLodashArray: complete data from server
/// pageNum: page to return - 1 based
/// pageSize: max number of items to return
/// filterFn: function to filter results return true/false to determine inclusion
/// sortField: object property to sort on
/// sortDir: direction of sort
export function getPage(srcLodashArray, pageNum, pageSize, filterFn, sortField, sortDir) {
  const filtered = srcLodashArray.filter((o) => filterFn(o));
  const total_len = filtered.size();
  const total_pages = Math.trunc(total_len / pageSize) + (total_len % pageSize == 0 ? 0 : 1);
  const result = filtered
    .orderBy([sortField], [sortDir]) // apply order
    .drop((pageNum - 1) * pageSize) // then fetch required page
    .take(pageSize);
  return {
    data: result.value(),
    totalPages: total_pages
  };
}
