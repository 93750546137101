import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

export default function (utcDateTime, dateOnly, dtFormat) {
  dayjs.extend(utc);
  if (!dtFormat) dtFormat = 'MMM D YYYY';
  if (dateOnly) return dayjs(utcDateTime).local().format(`${dtFormat}`);
  return dayjs(utcDateTime).local().format(`${dtFormat}, h:mm A`);
}

export function getLocalDateTime(utcTime) {
  dayjs.extend(utc);
  return dayjs(utcTime).local().toDate();
}

export function setLocalTimeOnLocalDate(localDate, localHours, localMinutes) {
  return dayjs(localDate).hour(localHours).minute(localMinutes);
}

export function getFormattedLocalTime(utcTime, timeFormat) {
  dayjs.extend(utc);
  if (!timeFormat) timeFormat = 'h:mm A';
  return dayjs(utcTime).local().format(`${timeFormat}`);
}

export function getLocalTimeHour(utcDateTime) {
  const localDateTime = getLocalDayJsObject(utcDateTime);
  return localDateTime.hour();
}

export function getLocalTimeMinutes(utcDateTime) {
  const localDateTime = getLocalDayJsObject(utcDateTime);
  return localDateTime.minute();
}

function getLocalDayJsObject(jsDateTime) {
  //according to docs dayjs always returns local by default,
  //but since we have the utc extension this is more explicit
  return dayjs(jsDateTime).local();
}