import { Tooltip } from 'bootstrap';

export default function (eltag) {
  const tooltipTriggerList = [].slice.call(
    eltag.querySelectorAll('[data-bs-toggle="tooltip"]')
  );
  return tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new Tooltip(tooltipTriggerEl, { trigger: 'hover click' });
  });
}
