export function entryBorderClass(entryType) {
  const classes = {};
  switch (entryType) {
    case 'DailyThought':
      classes['border-primary'] = true;
      break;
    case 'SystemPrompt':
      classes['border-secondary'] = true;
      break;
    case 'Gratitude':
      classes['border-success'] = true;
      break;
    case 'Trauma':
      classes['border-danger'] = true;
      break;
    case 'ThoughtProfPrompt':
      classes['border-primary'] = true;
      break;
    case 'TraumaProfPrompt':
      classes['border-danger'] = true;
      break;
  }
  return classes;
};

export function entryTextClass(entryType) {
  const classes = {};
  switch (entryType) {
    case 'DailyThought':
      classes['text-primary'] = true;
      break;
    case 'SystemPrompt':
      classes['text-secondary'] = true;
      break;
    case 'Gratitude':
      classes['text-success'] = true;
      break;
    case 'Trauma':
      classes['text-danger'] = true;
      break;
    case 'ThoughtProfPrompt':
      classes['text-primary'] = true;
      break;
    case 'TraumaProfPrompt':
      classes['text-danger'] = true;
      break;
  }
  return classes;
};

export function entryIconType(entryType) {
  switch (entryType) {
    case 'DailyThought':
      return 'pen-nib';
    case 'SystemPrompt':
      return 'comment';
    case 'Gratitude':
      return 'heart';
    case 'Trauma':
      return 'burn';
    case 'ThoughtProfPrompt':
      return 'pen-nib';
    case 'TraumaProfPrompt':
      return 'burn';
  }
}
